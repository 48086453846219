export default {
  label: {
    ok: '是',
    confirm: '确认',
    cancel: '取消',
    submit: '提交',
  },
  tip: {
    success: '成功',
  },
  text: {},
};
